import React from 'react'

export const Footer = () => {
    return (<>
        <footer className="footer" id="footer">
            <div className="container-fluid">
                <div className="row text-center">
                    <div className="col-md-12">
                        <div className="footer__inner">
                            <p>
                                &copy; Copyright Saga KL AS 2021          </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </>)
}

export default Footer