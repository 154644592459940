import React from 'react'
import { Link } from 'gatsby'

export const Navigation = () => {
    return (
        <nav className="navbar navbar-toggleable-md fixed-top">
            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#primary-navigation" aria-controls="primary-navigation" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon">≡</span>
            </button>
            <Link className="navbar-brand " to="/"><img src="../img/logo-liggende-new.png" className="navbar__logo" alt="Saga KL logo" /></Link>


            <div className="collapse navbar-collapse justify-content-end" id="primary-navigation">
                <ul className="navbar-nav">
                        <li className="nav-item">
                                <a className="nav-link nav-link--shop" href="https://sagakl.apogeestorefront.com/storefront/index.ep" target="_blank" rel="noreferrer">Nettbutikk</a>
                            </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/logos">Logoer</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/colors">Farger</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/typography">Typografi</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/images">Profilbilder</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/graphic-elements">Grafiske elementer</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/stationary">Aksidens</Link>
                    </li>
                </ul>
            </div>
        </nav>

    )
}

export default Navigation